/* Tabs */
.tabs .nav-tabs,
.tabs .nav-tabs .nav-link {
	border: 0 !important;
}
.tabs .nav-tabs .nav-link {
	padding: 15px 30px;
	max-width: 250px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #666666;
}
.tabs .nav-tabs .nav-link:hover,
.tabs .nav-tabs .nav-link:focus {
	color: #666666;
	background-color: #fff;
}
.tabs .nav-tabs .nav-link.active,
.tabs .nav-tabs .nav-item.show .nav-link {
	color: #fff;
	background-color: #666666;
}
/* Links */
.tabs .tab-content {
	padding: 50px;
}
.tabs .tab-content .link {
	padding-top: 15px;
	padding-bottom: 15px;
}
.tabs .tab-content .link:hover .card {
	border-color: #bbbbbb;
}
.tabs .tab-content .link:hover .card .card-img-top {
	transform: scale(0.9);
}
.tabs .tab-content .link:hover h5 {
	color: #bbbbbb;
}
.tabs .tab-content .link .card .card-img-top {
	object-fit: contain;
	height: 150px;
	transform: scale(0.8);
	transition: transform 0.5s ease;
}
.tabs .tab-content .link h5 {
	min-height: 26px;
}
